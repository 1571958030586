// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-akupunktur-js": () => import("./../../../src/pages/angebot/akupunktur.js" /* webpackChunkName: "component---src-pages-angebot-akupunktur-js" */),
  "component---src-pages-angebot-js": () => import("./../../../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-angebot-kraeutermedizin-js": () => import("./../../../src/pages/angebot/kraeutermedizin.js" /* webpackChunkName: "component---src-pages-angebot-kraeutermedizin-js" */),
  "component---src-pages-angebot-moxa-js": () => import("./../../../src/pages/angebot/moxa.js" /* webpackChunkName: "component---src-pages-angebot-moxa-js" */),
  "component---src-pages-angebot-schroepfen-js": () => import("./../../../src/pages/angebot/schroepfen.js" /* webpackChunkName: "component---src-pages-angebot-schroepfen-js" */),
  "component---src-pages-anwendungen-endometriose-js": () => import("./../../../src/pages/anwendungen/endometriose.js" /* webpackChunkName: "component---src-pages-anwendungen-endometriose-js" */),
  "component---src-pages-anwendungen-geburtakupunktur-js": () => import("./../../../src/pages/anwendungen/geburtakupunktur.js" /* webpackChunkName: "component---src-pages-anwendungen-geburtakupunktur-js" */),
  "component---src-pages-anwendungen-js": () => import("./../../../src/pages/anwendungen.js" /* webpackChunkName: "component---src-pages-anwendungen-js" */),
  "component---src-pages-anwendungen-kinderwunsch-js": () => import("./../../../src/pages/anwendungen/kinderwunsch.js" /* webpackChunkName: "component---src-pages-anwendungen-kinderwunsch-js" */),
  "component---src-pages-anwendungen-menstruationsstoerungen-js": () => import("./../../../src/pages/anwendungen/menstruationsstoerungen.js" /* webpackChunkName: "component---src-pages-anwendungen-menstruationsstoerungen-js" */),
  "component---src-pages-anwendungen-pcosyndrom-js": () => import("./../../../src/pages/anwendungen/pcosyndrom.js" /* webpackChunkName: "component---src-pages-anwendungen-pcosyndrom-js" */),
  "component---src-pages-anwendungen-schwangerschaftsbeschwerden-js": () => import("./../../../src/pages/anwendungen/schwangerschaftsbeschwerden.js" /* webpackChunkName: "component---src-pages-anwendungen-schwangerschaftsbeschwerden-js" */),
  "component---src-pages-anwendungen-spermiogramm-js": () => import("./../../../src/pages/anwendungen/spermiogramm.js" /* webpackChunkName: "component---src-pages-anwendungen-spermiogramm-js" */),
  "component---src-pages-anwendungen-wochenbett-js": () => import("./../../../src/pages/anwendungen/wochenbett.js" /* webpackChunkName: "component---src-pages-anwendungen-wochenbett-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kosten-js": () => import("./../../../src/pages/kosten.js" /* webpackChunkName: "component---src-pages-kosten-js" */),
  "component---src-pages-netzwerk-js": () => import("./../../../src/pages/netzwerk.js" /* webpackChunkName: "component---src-pages-netzwerk-js" */),
  "component---src-pages-ueber-mich-js": () => import("./../../../src/pages/ueber-mich.js" /* webpackChunkName: "component---src-pages-ueber-mich-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

